

























































































































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

.filterList {
  margin-right: 8px;
  color: #FFFFFF;
  background-color: $colorPrimary !important;

  &:hover {
    color: #000000;
  }
}

.filterArea {
  margin-top: 16px;
  background-color: $colorBlueBaby;
  padding: 8px 16px 8px 16px;
  align-items: center;
  border-radius: 16px;
}

.f {
  width: 100%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  &-select {
    min-width: 50x;
    display: flex;
    flex-wrap: wrap;
    &-title {
      font-weight: bold;
      margin-right: 5px;
    }
    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-right: 5px;
      border-radius: 5px;
      padding-left: 3px;
      font-size: 14px;
      font-weight: bold;
      color: #5cbc67;
      cursor: pointer;
      position: relative;
      &:hover{
        color: white;
        background: #5cbc67;
      }
    }
  }
}
